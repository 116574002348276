import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <img src={"assets/evyo.png"} alt="logo" className="logo" />
      <div className="footer">
        <img src={"assets/krnic_logo.svg"} alt="logo" className="logo" style={{ width: 50, marginBottom: 5 }} />
        <br />
        Powered by{" "}
        <a href="https://www.krnic.be" target="_blank" rel="noopener noreferrer">
          KRNIC CommV.
        </a>
      </div>
    </div>
  );
}

export default App;
